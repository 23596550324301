import _ from "lodash";

export default {
    install(Vue, options) {
        Vue.mixin({
            data() {
                return {
                    justification: {
                        raison: '',
                        justificatifs: [],
                    }
                }
            },
            methods: {
                async getJustification() {
                    //réinitialise la justification
                    this.justification.raison = '';
                    this.justification.justificatifs = [];

                    return this.$swal({
                        icon: "info",
                        iconColor: "#1F4391",
                        title: "Veuillez ajouter une justification à votre modification",
                        confirmButtonText: "Valider",
                        showDenyButton: true,
                        denyButtonText: "Annuler",
                        showCloseButton: true,
                        showLoaderOnConfirm: true,
                        width: "800px",
                        customClass: {
                            icon: "mb-2",
                            title: "mb-2",
                            text: "mb-1",
                            confirmButton: "btn btn-primary w-100",
                            denyButton: "btn btn-outline-primary text-primary w-100 my-1",
                        },
                        html: `
                        <input type="text" class="form-control mb-2" id="justification-raison" placeholder="Votre raison">
                        <input type="file" class="form-control mb-2" id="justification-pieces" multiple>
                      `,
                        preConfirm: async (response) => {
                            if (!document.getElementById("justification-raison").value.length) {
                                this.$swal.showValidationMessage("Veuillez entrer une raison");
                            }

                            this.justification.raison = document.getElementById("justification-raison").value;

                            const inputFiles = document.getElementById("justification-pieces").files;
                            if (inputFiles) {
                                for(const file of inputFiles) {
                                    await this.handleJustificationFile(file);
                                }
                            }
                            return;
                        },
                    }).then((result) => {
                        return result.isConfirmed ? true : false;
                    });
                },
                //TODO traîtement asynchrone
                handleJustificationFile(file) {
                    return new Promise((resolve, reject) => {
                        if (!file) return resolve();

                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            const b64 = reader.result;
                            this.justification.justificatifs.push(b64);
                            return resolve();
                        };
                        reader.onerror = (error) => {
                            console.log("Error: ", error);
                            alert("Erreur prise en compte fichier");
                            return reject(error);
                        };
                    })
                },
            }
        })
    }
}