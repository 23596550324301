import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';
import { isUserLoggedIn } from '@/auth/utils';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'manager',
      component: () => import(/* webpackChunkName: "manager" */'@/views/Manager.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Manager de contrats',
        breadcrumb: [
          {
            text: 'Manager',
            active: true,
          },
        ],
      },
    },

    {
      path: '/new-model',
      name: 'newModel',
      component: () => import(/* webpackChunkName: "model-creation" */'@/views/ModelCreation.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Création d\'un modèle',
        breadcrumb: [
          {
            text: 'Création d\'un modèle',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editor/:id',
      name: 'editor',
      component: () => import(/* webpackChunkName: "editor" */'@/views/Editor.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Composeur de documents',
        breadcrumb: [
          {
            text: 'Composeur',
            active: true,
          },
        ],
      },
    },
    {
      path: '/fill/:id',
      name: 'fill',
      component: () => import(/* webpackChunkName: "fill" */'@/views/Fill.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Remplissage du document',
        breadcrumb: [
          {
            text: 'Remplissage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/document-to-html',
      name: 'documentToHtml',
      component: () => import(/* webpackChunkName: "fill" */'@/views/DocumentToHtml.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Document -> HTML',
        breadcrumb: [],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import(/* webpackChunkName: "404" */'@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   // Redirect to login if not logged in
//   if (to.name !== 'etude' && !isLoggedIn) return next({ name: 'etude' })

//   return next()
// })

export default router
